import { ConfirmationFormValues } from '~/pages/auth/confirm/components/confirmation-form.tsx'
import RegistrationFormRequest from '~/pages/auth/registration/components/registration-form.request.ts'
import api from '~/services/api'

const RegistrationService = {
  confirmAccount: async (id: number, token: string, data?: ConfirmationFormValues) => {
    await api.post(`/auth/verify/${id}`, {
      data,
      token,
    })
  },
  register: async (data: RegistrationFormRequest) => {
    await api.post(`/auth/register`, data)
  },
}

export default RegistrationService
