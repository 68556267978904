import { FormikHelpers } from 'formik'
import { signIn } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { navigate, reload } from 'vike/client/router'

import logo from '~/assets/logo.webp'
import LoginForm from '~/pages/auth/login/components/login-form'
import { usePageContext } from '~/renderer/usePageContext.tsx'
import RegistrationService from '~/services/registration-service.ts'

export type FormValues = {
  email: string
  password: string
}

export const Page = () => {
  const { urlParsed } = usePageContext()
  const { search } = urlParsed
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    const confirmEmail = async () => {
      await RegistrationService.confirmAccount(
        parseInt(urlParsed.search.id, 10),
        urlParsed.search.token
      )
    }

    if (urlParsed.search.token) {
      confirmEmail()
    }
  }, [urlParsed.search.id, urlParsed.search.token])

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const res = await signIn('login', {
      username: values.email,
      password: values.password,
      redirect: false,
    })

    if (!res?.ok) {
      setHasErrors(true)
    }

    setSubmitting(false)

    if (search.redirected) {
      return navigate('/')
    }

    return reload()
  }

  return (
    <div className="h-full">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-16 w-auto" src={logo} alt="Your Company" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <LoginForm onSubmit={handleSubmit} hasErrors={hasErrors} />
      </div>
    </div>
  )
}
